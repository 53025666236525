import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import Swal from "sweetalert2"; // Import SweetAlert2
import withReactContent from "sweetalert2-react-content"; // Import React components for SweetAlert2

import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingTable from "../../components/LoadingTable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import getDecryptData from "../../../helpers/getDecryptData";

const MySwal = withReactContent(Swal); // Create a SweetAlert2 instance with React components

function AllJobDoctor() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    const decryptdatajson = await getDecryptData();
    const id = decryptdatajson?._id;
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/admin/all-job-doctor-fillter?page=${currentPage}&limit=${limit}&search=${searchTerm}&userId=${id}`
      );
      setData(response.data.Job);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle delete
  const handleDelete = (categoryId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      customClass: {
        popup: "custom-swal-popup", // Add a class to the entire dialog
        confirmButton: "btn-danger", // Add a class to the confirm button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/admin/delete-job/${categoryId}`)
          .then(() => {
            // Refresh the data or update the state after successful deletion
            fetchData();
            toast.success("Deleting Jobs Success!");
          })
          .catch((error) => {
            console.error("Error Jobs data:", error);
            toast.error("Error Jobs data");
          });
      }
    });
  };

  const inputRef = React.createRef();

  const handleSearchInputChange = (e) => {
    setCurrentPage(1);
    setTotalPages(1);
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    fetchData(currentPage);
  };

  const handleStatusChange = (StatusId) => async (event) => {
    const newStatus = event.target.value;
    setLoading(true);

    try {
      await axiosInstance.put(`/admin/update-job/${StatusId}`, {
        status: newStatus,
      });
      setLoading(false);
      fetchData(currentPage);
      toast.success("Status Updated success!");
    } catch (error) {
      console.error("Error updating rating status:", error);
      setLoading(false);
    }
  };

  const [isToggled, setIsToggled] = useState(false);
  // Toggle the value between true and false
  const toggleState = () => {
    setIsToggled((prevState) => !prevState);
  };
  const [jobData, setJobData] = useState(null); // Store the job data
  const [jobLoading, setJobLoading] = useState(false); // Store the job data

  // Fetch job data by ID
  const fetchJob = async (id) => {
    try {
      setJobLoading(true);
      const { data } = await axiosInstance.get(`/admin/get-job-id/${id}`);
      const { success } = data;

      if (success) {
        // If job is successfully fetched, update the jobData state
        setJobData(data.Job); // Assuming `job` is the fetched job data
      }

      console.log("Job fetched successfully", data);
    } catch (error) {
      console.error("Error during job fetch:", error);
      toast.error(error.response?.data?.message || "Error fetching job");
    } finally {
      setJobLoading(false);
    }
  };

  // Fetch job data by ID
  const ApplyJob = async (id) => {
    const decryptdatajson = await getDecryptData();
    const userId = decryptdatajson?._id;
    try {
      const { data } = await toast.promise(
        axiosInstance.get(`/admin/apply-job-id/${id}/${userId}`),
        {
          loading: "Applying job...", // Loading message
          success: "Apply job sucesssfully!", // Success message
          error: "Failed to apply job", // Error message
        }
      );

      const { success } = data;

      if (success) {
        fetchData(currentPage);
      }
      console.log("Job fetched successfully", data);
    } catch (error) {
      console.error("Error during job fetch:", error);
      toast.error(error.response?.data?.message || "Error fetching job");
    } finally {
      setJobLoading(false);
    }
  };

  // Handle the click event: toggle state and fetch job
  const handleClick = (id) => {
    setJobLoading(true);
    setJobData([]);
    toggleState(); // Toggle the state (could be for sidebar visibility or something else)
    fetchJob(id); // Fetch the job data using the row's ID
  };

  return (
    <>
      <Sidebar />
      <main className="content position-relative">
        <Navbar />
        <div className="py-4">
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4"> All Jobs</h1>
            </div>
            <div></div>
          </div>
        </div>

        <div className="card  bg-light">
          <div className="table-responsive py-4">
            {loading ? (
              <LoadingTable />
            ) : (
              <>
                <div className="datatable-top">
                  <div className="datatable-dropdown">
                    <label>
                      <select
                        className="datatable-selector"
                        value={limit}
                        onChange={(e) => {
                          setlimit(parseInt(e.target.value, 10));
                          setCurrentPage(1); // Reset current page to 1 when the limit changes
                        }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                      </select>{" "}
                      entries per page
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div className="datatable-search">
                      <input
                        className="datatable-input"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                    <button className="btn btn-primary" onClick={handleSearch}>
                      {" "}
                      Search
                    </button>
                  </div>
                </div>

                <div
                  className="w-100 p-4 row"
                  style={{ backgroundColor: "#f2f4f6" }}
                >
                  {data.length === 0 ? (
                    <>
                      <div className="col mb-4">
                        <div
                          className={` p-4 bg-white  cards p-2  text-center fw-bold  shadow gap-4 rounded 1`}
                        >
                          <img
                            src="/search-image.svg"
                            style={{ maxWidth: "300px", width: "80%" }}
                            className="mx-auto d-block"
                          />
                          <h3 className="mt-4 fw-light">
                            No Jobs Found{" "}
                            <i className="ri-emotion-sad-line"></i>{" "}
                          </h3>
                          <p>Please ensure that you have added a job post.</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    data.map((row) => (
                      <div key={row._id} className="col-md-6 mb-4">
                        <div className="bg-white cards   p-4 rounded  shadow gap-4  mb-2  ">
                          <div className="row align-items-center">
                            <div className="col-md-9">
                              <div className="d-flex gap-2 align-items-center mb-2">
                                <div
                                  className="mb-0 font-small p-2 bg-light rounded "
                                  style={{
                                    backgroundColor: "rgb(242, 244, 246)",
                                  }}
                                >
                                  <i className="ri-shield-cross-fill h3"></i>
                                </div>

                                <div className="col ">
                                  <div className="mb-0 font-small  ">
                                    <p className="fw-bold text-gray-900 mb-0">
                                      {row.jobTitle}
                                    </p>
                                  </div>

                                  <span className="text-gray-500 ">
                                    ₹{row?.startSalary}
                                  </span>
                                  <span className=" text-gray-500"> - </span>
                                  <span className="text-gray-500 ">
                                    ₹{row?.endSalary} per month
                                  </span>
                                </div>
                              </div>
                              <p className="mb-1">
                                <span className="text-gray-500 ">
                                  <i className="ri-map-pin-line me-2" />
                                  {row?.city}, {row?.statename}
                                </span>
                              </p>
                              <p className="mb-1">
                                <span className="text-gray-500 ">
                                  <i className="ri-building-line me-2" />
                                  {row?.c_name}
                                </span>
                              </p>
                              <button
                                class="btn btn-outline-success   badge-sm me-2"
                                type="button"
                                style={{ borderRadius: "50px" }}
                              >
                                <i className="ri-briefcase-fill me-1"></i>1
                              </button>

                              <button
                                class="btn btn-outline-success   badge-sm "
                                type="button"
                                style={{ borderRadius: "50px" }}
                              >
                                Apply First
                              </button>
                            </div>
                            <div className="col-md-3 py-3">
                              <Link
                                type="button"
                                onClick={() => {
                                  handleClick(row?._id); // Call fetchjob with the row's _id
                                }}
                                className="btn btn-primary d-inline-flex align-items-center bg-dark  w-100 justify-content-center mb-3"
                              >
                                <i className="ri-search-eye-fill me-1" /> View
                              </Link>
                              <button
                                type="button"
                                className="btn btn-secondary d-inline-flex align-items-center bg-dark  w-100 justify-content-center"
                                onClick={() => {
                                  ApplyJob(row?._id); // Call fetchjob with the row's _id
                                }}
                              >
                                <i className="ri-send-plane-2-fill me-1" />{" "}
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <div className="datatable-bottom">
                  <div className="datatable-info"> </div>
                  <nav className="datatable-pagination">
                    <ul class="datatable-pagination-list">
                      <li
                        className={`datatable-pagination-list-item ${
                          currentPage === 1
                            ? "datatable-hidden"
                            : "datatable-active"
                        }`}
                      >
                        <button
                          data-page="1"
                          class="datatable-pagination-list-item-link"
                          onClick={handlePreviousPage}
                          disabled={loading || currentPage === 1}
                          aria-label="Page 1"
                        >
                          ‹
                        </button>
                      </li>
                      <li>
                        <div className="datatable-info py-1 px-3">
                          Showing Page{" "}
                          <strong>
                            Showing Page {currentPage} of {totalPages}
                          </strong>{" "}
                        </div>
                      </li>
                      <li
                        className={`datatable-pagination-list-item ${
                          currentPage === totalPages
                            ? "datatable-hidden"
                            : "datatable-active"
                        }`}
                      >
                        <button
                          data-page="2"
                          class="datatable-pagination-list-item-link"
                          aria-label="Page 2"
                          onClick={handleNextPage}
                          disabled={loading || currentPage === totalPages}
                        >
                          {" "}
                          {loading ? "Loading..." : "›"}{" "}
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={`job-sidebar ${isToggled && "active"}`}>
          <div className="h-auto p-4" style={{ overflow: "auto" }}>
            <div className="py-4">
              <div className="d-flex gap-4 w-100 flex-wrap">
                <div>
                  <button
                    type="button"
                    onClick={toggleState}
                    className="btn btn-primary d-inline-flex align-items-center bg-dark  w-100 justify-content-center mb-3"
                  >
                    <i class="ri-arrow-left-line me-1" /> back
                  </button>
                </div>

                <div className="mb-3 mb-lg-0"></div>
              </div>
              {jobLoading ? (
                <>
                  <div
                    style={{ minHeight: "50vh" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div
                      className="spinner-border"
                      style={{ width: 100, height: 100 }}
                      role="status"
                    ></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex gap-2 align-items-center mb-2 my-4">
                    <div
                      className="mb-0 font-small p-2 bg-light rounded "
                      style={{
                        backgroundColor: "rgb(242, 244, 246)",
                      }}
                    >
                      <i className="ri-shield-cross-fill h3"></i>
                    </div>

                    <div className="col ">
                      <div className="mb-0 font-small  ">
                        <p className="fw-bold text-gray-900 mb-0">
                          {jobData?.jobTitle}
                        </p>
                      </div>

                      <span className="text-gray-500 ">
                        ₹{jobData?.startSalary}
                      </span>
                      <span className=" text-gray-500"> - </span>
                      <span className="text-gray-500 ">
                        ₹{jobData?.endSalary} per month
                      </span>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      {/* Tab */}
                      <nav>
                        <div
                          className="nav nav-tabs mb-4"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            className="nav-item nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            Job Details
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            href="#nav-profile"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            Company Details
                          </a>
                        </div>
                      </nav>
                      <div
                        className="tab-content p-4 rounded"
                        id="nav-tabContent"
                        style={{ backgroundColor: "rgb(242, 244, 246)" }}
                      >
                        <div
                          className="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className=" p-3 rounded alert alert-success">
                            <p>
                              {" "}
                              <b>Languages : </b>{" "}
                              {jobData?.englishReq === 1
                                ? "Does not speak english"
                                : jobData?.englishReq === 2
                                ? "Speaks Thoda English"
                                : jobData?.englishReq === 3
                                ? "Speaks Good English"
                                : jobData?.englishReq === 4 &&
                                  "Speaks Fluent English"}
                            </p>

                            <p>
                              {" "}
                              <b>Job Role : </b> {jobData?.jobRole}
                            </p>

                            <p>
                              {" "}
                              <b>Skill Required : </b>{" "}
                              {jobData?.skill?.map((skill, index) => (
                                <span key={index}>
                                  {skill},{" "}
                                  {/* Or however you want to display each skill */}
                                </span>
                              ))}
                            </p>

                            <p>
                              {" "}
                              <b>Job Timings : </b> {jobData?.jobTimings}
                            </p>
                          </div>

                          <div className=" p-3 rounded bg-white mb-3">
                            <p className="fw-bold text-dark mb-1">
                              <i class="ri-file-text-fill me-2 "></i>
                              Job Description
                            </p>
                            <p className="mb-0">{jobData?.jobDescription} </p>
                          </div>

                          <div className=" p-3 rounded bg-white mb-3">
                            <p className="fw-bold text-dark mb-1">
                              <i class="ri-file-text-fill me-2 "></i>
                              Company details
                            </p>
                            <div className="alert alert-secondary ">
                              <b>Organization Size</b>
                              <p className="mb-0">
                                {" "}
                                {jobData?.sizeOrganization}{" "}
                              </p>
                            </div>
                          </div>

                          <div className=" p-3 rounded bg-white">
                            <p className="fw-bold text-dark mb-2">
                              <i class="ri-building-fill me-2 "></i>
                              Interview Details
                            </p>
                            <div className="alert alert-secondary ">
                              <b>Contact Person</b>
                              <p className="mb-0"> {jobData?.personProfile} </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <div className=" p-3 rounded bg-white mb-3">
                            <div className="alert alert-secondary mb-3">
                              <b>Company Name</b>
                              <p className="mb-0"> {jobData?.c_name} </p>
                            </div>
                            <div className="alert alert-secondary mb-3">
                              <b>Contact Person Name </b>
                              <p className="mb-0"> {jobData?.p_name} </p>
                            </div>

                            <div className="alert alert-secondary mb-3">
                              <b>Contact Person Profie </b>
                              <p className="mb-0"> {jobData?.personProfile} </p>
                            </div>

                            <div className="alert alert-secondary mb-3">
                              <b> Company Address </b>
                              <p className="mb-0"> {jobData?.address} </p>
                            </div>

                            <div className="alert alert-secondary mb-3">
                              <b>Organization Size</b>
                              <p className="mb-0">
                                {" "}
                                {jobData?.sizeOrganization}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End of tab */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AllJobDoctor;
