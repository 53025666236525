import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance, { weburl } from "../../../axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import toast from "react-hot-toast";
import Gallery from "../../components/Gallery";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import Select from "react-select";
import { triggerChooseimg } from "../../components/ChooseImg";

const JobView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/admin/all-job-view?page=${currentPage}&limit=${limit}&search=${searchTerm}&jobId=${id}`
      );
      setData(response.data.Job);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      console.log("jobsjobsjobs", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusChange = (StatusId) => async (event) => {
    const newStatus = event.target.value;
    setLoading(true);

    try {
      await axiosInstance.put(`/admin/update-job-status/${StatusId}`, {
        status: newStatus,
      });
      setLoading(false);
      fetchData(currentPage);
      toast.success("Status Updated success!");
    } catch (error) {
      console.error("Error updating rating status:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <div className="row">
            <div className="col-lg-3">
              <div className="bg-white cards   p-4 rounded shadow gap-4 tabs-box mb-2">
                <div className="d-flex align-items-center justify-content-between">
                  <h5>All Filters</h5>
                  <button class="badge bg-primary fw-light ms-2">Clear</button>
                </div>

                <div class="accordion mt-4" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Candidate Status
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed"
                          />
                          <label className="form-check-label" for="Interviewed">
                            Interviewed
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed0"
                          />
                          <label
                            className="form-check-label"
                            for="Interviewed0"
                          >
                            Hired
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed1"
                          />
                          <label
                            className="form-check-label"
                            for="Interviewed1"
                          >
                            Reject
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed2"
                          />
                          <label
                            className="form-check-label"
                            for="Interviewed2"
                          >
                            have Not Talked
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Candidate Relevancy
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="high"
                          />
                          <label className="form-check-label" for="high">
                            High Match
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="low"
                          />
                          <label className="form-check-label" for="low">
                            Low Match
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="bg-white cards d-flex p-4 rounded shadow gap-4 tabs-box mb-2 overflow-auto">
                <a className=" mytabs active">All Candidates (190) </a>
                <a className="mytabs">Applied Candidates (100) </a>
                <a className="mytabs  ">Contact Candidates (90) </a>
              </div>

              {data.map((row) => (
                <>
                  <div className="col ">
                    <div className="bg-white cards   p-4 rounded shadow gap-4  mb-2  ">
                      <div className="media d-flex align-items-center mb-3">
                        <img
                          className=" rounded"
                          alt="Image placeholder"
                          width={60}
                          src={
                            row.userId &&
                            typeof row.userId.profile === "string" &&
                            row.userId.profile !== ""
                              ? weburl +
                                row.userId.profile.replace("public", "")
                              : "/assets/img/placeholder.png"
                          }
                          onError={(e) =>
                            (e.target.src = "/assets/img/placeholder.png")
                          }
                        />
                        <div className="media-body ms-2 text-dark align-items-center  ">
                          <span className="mb-0 font-medium fw-bold text-gray-900">
                            {row.userId.username}
                          </span>
                          <div className="mb-0 font-small  ">
                            <span className="fw-bold text-gray-500">
                              {" "}
                              Location :{" "}
                              <b className="text-gray-900">
                                {row.userId.address}
                              </b>
                            </span>

                            <span
                              className="fw-bold text-gray-500 ms-2 border-left ps-2"
                              style={{ borderLeft: "2px solid grey" }}
                            >
                              {" "}
                              Current Sallary:{" "}
                              <b className="text-gray-900"> ₹2500/month </b>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="text-gray-500 text-nowrap">
                          Key Info:
                        </div>
                        <div className="text-gray-500  ">
                          <span class="badge bg-primary fw-light ms-2">
                            <i className="ri-graduation-cap-fill"></i> Graduate
                          </span>

                          <span class="badge bg-primary fw-light ms-2">
                            <i className="ri-graduation-cap-fill"></i> Speaks
                            Good English
                          </span>

                          <span class="badge bg-primary fw-light ms-2">
                            <i className="ri-shopping-bag-fill"></i> 8 Years
                          </span>

                          <span class="badge bg-primary fw-light ms-2">
                            <i className="ri-men-line"></i> Male ,25Y
                          </span>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="text-gray-500 text-nowrap">
                          Previous Job:
                        </div>
                        <div className="text-gray-500  ms-2">
                          <span className="text-gray-900">
                            {" "}
                            Web Designer At Ontech Digital Technologies Pvt.
                            Ltd.
                          </span>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="text-gray-500 text-nowrap">
                          Industries:{" "}
                        </div>
                        <div className="text-gray-500  ms-2">
                          <span className="text-gray-900">
                            {" "}
                            Software & IT Services
                          </span>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="text-gray-500 text-nowrap">
                          Language:{" "}
                        </div>
                        <div className="text-gray-500  ms-2">
                          <span className="text-gray-900"> Hind, English</span>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="text-gray-500 text-nowrap">Skill:</div>
                        <div className="text-gray-500  ">
                          {row.userId?.specialization &&
                            row.userId.specialization.length &&
                            row.userId.specialization.map((row) => (
                              <span class="badge bg-primary fw-light ms-2">
                                {row}
                              </span>
                            ))}
                        </div>
                      </div>
                      <hr />
                      <div className="row align-items-center">
                        <div className="col-md-3 mb-3">
                          <select
                            className="form-select mb-0 bg-secondary text-dark mb-3"
                            id="status"
                            name="status"
                            value={row.status}
                            onChange={handleStatusChange(row._id)}
                          >
                            <option selected="selected">Select Status</option>
                            <option value={1} selected={row.status === 1}>
                              Called For Interview
                            </option>
                            <option value={2} selected={row.status === 2}>
                              Interviewwed
                            </option>
                            <option value={3} selected={row.status === 3}>
                              Hired
                            </option>
                            <option value={4} selected={row.status === 4}>
                              Rejected
                            </option>
                            <option value={5} selected={row.status === 5}>
                              have Not Talked
                            </option>
                            <option value={6} selected={row.status === 6}>
                              {" "}
                              Talked{" "}
                            </option>
                          </select>
                        </div>

                        <div className="col-md-9 mb-3 text-end">
                          <button
                            class="btn btn-outline-primary mb-3"
                            type="button"
                          >
                            <i className="ri-file-reduce-fill"></i> View Profile
                            & Resume
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary d-inline-flex align-items-center ms-2 mb-3"
                          >
                            <i className="ri-send-plane-2-fill me-1"></i> Send
                            SMS
                          </button>

                          <button
                            type="button"
                            className="btn btn-success text-white d-inline-flex align-items-center ms-2 mb-3"
                          >
                            <i className="ri-whatsapp-line me-1"></i> Whatsapp
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default JobView;
