import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axiosInstance, { weburl } from "../../../axiosInstance";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import getCookie from "../../../helpers/getCookie";
import getDecryptData from "../../../helpers/getDecryptData";

const ProfileHospitalPending = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Add loading state

  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state
  const [data, setData] = useState([]);
  const [dataNew, setDataNew] = useState([]);

  const [inputs, setInputs] = useState({
    type: "",
    username: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    pincode: "",
    Gender: "1",
    DOB: "",
    address: "",
    specialization: [],
    // profile: null, // Changed to null to match file type
    DLfile: null,
    // AadhaarFront: null,
    // AadhaarBack: null,
    // PoliceVerification: null,
    // PassPort: null,
    // Electricity: null,
    // WaterBill: null,
  });

  // //handle input change
  // const handleChange = (e) => {
  //     const { name, value, type, checked, files } = e.target;
  //     setInputs((prevData) => ({
  //         ...prevData,
  //         [name]:
  //             type === "checkbox" ? checked : type === "file" ? files[0] : value,
  //     }));
  // };

  const capitalizeEachWord = (str) => {
    if (!str) return str;
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const CreateSlug = (str) => {
    var slug = str;

    // Convert to lowercase
    slug = slug.toLowerCase();

    // Replace spaces with hyphens
    slug = slug.replace(/\s+/g, "-");

    // Remove special characters (only allow alphanumeric, hyphens, and underscores)
    slug = slug.replace(/[^a-z0-9\-]/g, "");

    return slug;
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    let newValue;

    // Handle multi-checkbox logic
    if (type === "checkbox") {
      if (name === "departmentsAvailable") {
        // If 'specialization' is being updated, add/remove values from the array
        newValue = checked
          ? [...(inputs.departmentsAvailable || []), value] // Add value if checked
          : (inputs.departmentsAvailable || []).filter(
              (item) => item !== value
            ); // Remove value if unchecked
      } else if (name === "interestedServices") {
        // If 'specialization' is being updated, add/remove values from the array
        newValue = checked
          ? [...(inputs.interestedServices || []), value] // Add value if checked
          : (inputs.interestedServices || []).filter((item) => item !== value); // Remove value if unchecked
      } else {
        newValue = checked;
      }
    } else if (type === "file") {
      // Handle file input
      newValue = files[0];
    } else {
      // Handle normal text inputs
      newValue = value;
    }

    // Capitalize the text for 'username' field
    if (name === "username") {
      newValue = capitalizeEachWord(newValue);
      // Update the state with the new value
      setInputs((prevData) => ({
        ...prevData,
        profile_url: CreateSlug(newValue),
      }));
    }

    // Capitalize the text for 'username' field
    if (name === "profile_url") {
      newValue = CreateSlug(newValue);
    }

    // Update the state with the new value
    setInputs((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //form handle
  const fetchUserById = async () => {
    const decryptdatajson = await getDecryptData();
    const id = decryptdatajson?._id;

    const credentials = {
      id: id,
    };

    try {
      const { data } = await axiosInstance.post("/auth-user", credentials);
      const { success, token, existingUser, message } = data;

      if (success) {
        setInputs((prevData) => ({
          ...prevData,
          username: existingUser.username || "",
          phone: existingUser.phone || "",
          email: existingUser.email || "",
          address: existingUser.address || "",
          pincode: existingUser.pincode || "",
          state: existingUser.state || "",
          statename: existingUser.statename || "",
          country: existingUser.country || "",
          city: existingUser.city || "",
          numberOfBeds: existingUser.numberOfBeds || "",
          Designation: existingUser.Designation || "",
          interestedServices: existingUser.interestedServices || "",
          departmentsAvailable: existingUser.departmentsAvailable || "",
          typeHospital: existingUser.typeHospital || "",
          profile_url: existingUser.profile_url || "",
        }));
      }

      console.log("success", existingUser);
    } catch (error) {
      console.error("Error during login:", error);
      // Handle network errors, API issues, etc.
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchUserById();
  }, []);

  const handleSubmit = async () => {
    const decryptdatajson = await getDecryptData();

    const id = decryptdatajson?._id;

    setSubmitLoading(false);
    console.log(inputs);
    const fields = [
      { name: "username", message: "Please enter Full Name" },
      { name: "phone", message: "Please enter phone number" },
      { name: "email", message: "Please enter email" },
      // { name: "Gender", message: "Please enter Gender" },
      // { name: "DOB", message: "Please enter DOB" },
      // { name: "state", message: "Please enter State" },
      // { name: "pincode", message: "Please enter pincode" },
      // { name: "address", message: "Please enter address" },
      // { name: "profile", message: "Please choose profile photo" },
      // {
      //   name: "AadhaarFront",
      //   message: "Please choose Aadhaar Front photo ",
      // },
      // {
      //   name: "AadhaarBack",
      //   message: "Please choose Aadhaar Back photo ",
      // },
    ];

    for (const field of fields) {
      if (!inputs[field.name]) {
        toast.error(field.message);
        setSubmitLoading(true);
        return;
      }
    }

    console.log("inputsinputs", inputs);
    try {
      const { data } = await axiosInstance.put(
        `/admin/update-user-hospital/${id}`,
        inputs,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { success } = data;
      if (success) {
        navigate("/");

        toast.success("Account Created Successfully  ");
      }
    } catch (error) {
      console.error("Error On Signup:", error);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    console.log("response.data start");

    try {
      const response = await axiosInstance.get("/get-all-department");
      console.log("response.data.department,", response.data.Department);
      setData(response.data.Department);

      const response2 = await axiosInstance.get("/get-all-interested");
      console.log("response.data.interested,", response.data.Interested);
      setDataNew(response2.data.Interested);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <Navbar />
        <div className="py-4">
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="container pt-0">
              <div
                className="dashboard-content px-4 py-3 "
                style={{ maxWidth: "100vw" }}
              >
                <form className="m-b20 row">
                  <div className="card  border-0 bg-white p-4 mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="mb-4 text-danger h4">
                          Complete Your Profile{" "}
                          <i className="ri-error-warning-fill"></i>{" "}
                        </label>
                      </div>
                      <div className="mb-4">
                        <label className="form-label text-dark" htmlFor="name">
                          Full Name{" "}
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group   input-sm">
                          <input
                            type="text"
                            id="username"
                            className="form-control"
                            name="username"
                            value={inputs.username}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6">
                        <label className="form-label text-dark" htmlFor="email">
                          Email{" "}
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group   input-sm">
                          <input
                            readOnly
                            type="email"
                            id="email"
                            className="form-control"
                            name="email"
                            value={inputs.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6 ">
                        <label
                          className="form-label text-dark"
                          htmlFor="Gender"
                        >
                          Gender{" "}
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <select
                            className="form-control"
                            value={inputs.Gender}
                            name="Gender"
                            onChange={handleChange}
                            disabled
                          >
                            <option selected disabled>
                              {" "}
                              Select Gender
                            </option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="0">Other</option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <label
                          className="form-label text-dark"
                          htmlFor="Country"
                        >
                          Country
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <input
                            type="text"
                            id="country"
                            className="form-control custom-date"
                            name="country"
                            value={inputs.country}
                            readOnly
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <label className="form-label text-dark" htmlFor="email">
                          State
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <input
                            type="text"
                            id="statename"
                            readOnly
                            className="form-control custom-date"
                            name="statename"
                            value={inputs.statename}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <label className="form-label text-dark" htmlFor="city">
                          City
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <input
                            type="text"
                            id="city"
                            readOnly
                            className="form-control custom-date"
                            name="city"
                            value={inputs.city}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <label className="form-label text-dark" htmlFor="email">
                          Pincode
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <input
                            type="number"
                            readOnly
                            id="pincode"
                            className="form-control custom-date"
                            name="pincode"
                            value={inputs.pincode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card  border-0 bg-white p-4 mb-4">
                    <div className="row">
                      <div className="mb-4 col-6 ">
                        <label className="form-label text-dark" htmlFor="email">
                          Number of Beds
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <input
                            type="number"
                            id="numberOfBeds"
                            className="form-control custom-date"
                            name="numberOfBeds"
                            value={inputs.numberOfBeds}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <label
                          className="form-label text-dark"
                          htmlFor="Designation"
                        >
                          Designation
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="input-group    input-sm">
                          <input
                            type="text"
                            id="Designation"
                            className="form-control custom-date"
                            name="Designation"
                            value={inputs.Designation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <label
                          className="form-label text-dark"
                          htmlFor="jobLocation"
                        >
                          Type of Hospital
                          <span className="text-danger"> ( Required )</span>
                        </label>
                        <div className="    input-sm">
                          <div className="form-check d-block">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typeHospital"
                              id="typeHospital1"
                              value="1"
                              checked={
                                inputs.typeHospital === "1" ||
                                inputs.typeHospital === 1
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="typeHospital1"
                            >
                              Government
                            </label>
                          </div>

                          <div className="form-check d-block">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typeHospital"
                              id="typeHospital2"
                              value="2"
                              checked={
                                inputs.typeHospital === "2" ||
                                inputs.typeHospital === 2
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="typeHospital2"
                            >
                              Private
                            </label>
                          </div>

                          <div className="form-check d-block">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typeHospital"
                              id="typeHospital3"
                              value="3"
                              checked={inputs.typeHospital === "3"}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="typeHospital3"
                            >
                              Trust
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 col-6  col-md-6">
                        <label
                          className="form-label text-dark"
                          htmlFor="ExtraCertificationsFile"
                        >
                          Profile Image
                        </label>

                        <div className="openimage-container">
                          {inputs.ProfileFile &&
                          typeof inputs.ProfileFile === "string" &&
                          inputs.ProfileFile.indexOf(".pdf") !== -1 ? (
                            <embed
                              src={
                                inputs && inputs.ProfileFile !== ""
                                  ? weburl + inputs.ProfileFile
                                  : "/img/imageupload.png"
                              }
                              id="ProfileFileImage"
                              alt="Profile Image"
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                background: "#f8f9fa",
                                display: "none",
                                textAlign: "center",
                                lineHeight: "40vh",
                                borderRadius: 5,
                                objectFit: "contain",
                                border: "1px solid grey",
                              }}
                            />
                          ) : (
                            <img
                              src={
                                inputs && inputs.ProfileFile !== ""
                                  ? weburl + inputs.ProfileFile
                                  : "/img/imageupload.png"
                              }
                              id="ProfileFileImage"
                              alt="Profile Image"
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                background: "#f8f9fa",
                                display: "none",
                                textAlign: "center",
                                lineHeight: "40vh",
                                borderRadius: 5,
                                objectFit: "contain",
                                border: "1px solid grey",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            id="ProfileFile"
                            className="form-control"
                            name="ProfileFile"
                            accept=".jpg, .jpeg, .png, .pdf,.webp"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imgElement =
                                    document.getElementById("ProfileFileImage");
                                  // if (imgElement) {
                                  //   imgElement.src = e.target.result; // Set the image URL directly on the img element
                                  // }

                                  if (imgElement) {
                                    if (
                                      e.target.result.indexOf(
                                        "data:application/pdf"
                                      ) !== -1
                                    ) {
                                      // Get the computed styles (all styles applied to the element, including those from external stylesheets)
                                      var computedStyle =
                                        window.getComputedStyle(imgElement);

                                      // Get all computed styles as a string (including properties like background, border, etc.)
                                      var allStyles = "";
                                      for (
                                        var i = 0;
                                        i < computedStyle.length;
                                        i++
                                      ) {
                                        var styleProperty = computedStyle[i];
                                        allStyles +=
                                          styleProperty +
                                          ": " +
                                          computedStyle.getPropertyValue(
                                            styleProperty
                                          ) +
                                          "; ";
                                      }

                                      // Create the iframe element
                                      var iframe =
                                        document.createElement("iframe");

                                      // Set the iframe's src (same as the img src)
                                      iframe.src = e.target.result;

                                      // Apply all the computed styles to the iframe
                                      iframe.style.cssText = allStyles;

                                      // Optional: Ensure the iframe doesn't have a border (you can skip this if you want the iframe border)
                                      iframe.style.border = "none";
                                      iframe.id = imgElement.id;

                                      // Replace the image with the iframe
                                      imgElement.parentNode.replaceChild(
                                        iframe,
                                        imgElement
                                      );

                                      // imgElement.src = e.target.result;
                                    } else {
                                      // Get the computed styles (all styles applied to the element, including those from external stylesheets)
                                      var computedStyle =
                                        window.getComputedStyle(imgElement);

                                      // Get all computed styles as a string (including properties like background, border, etc.)
                                      var allStyles = "";
                                      for (
                                        var i = 0;
                                        i < computedStyle.length;
                                        i++
                                      ) {
                                        var styleProperty = computedStyle[i];
                                        allStyles +=
                                          styleProperty +
                                          ": " +
                                          computedStyle.getPropertyValue(
                                            styleProperty
                                          ) +
                                          "; ";
                                      }

                                      // Create the iframe element
                                      var iframe =
                                        document.createElement("img");

                                      // Set the iframe's src (same as the img src)
                                      iframe.src = e.target.result;

                                      // Apply all the computed styles to the iframe
                                      iframe.style.cssText = allStyles;

                                      // Optional: Ensure the iframe doesn't have a border (you can skip this if you want the iframe border)
                                      iframe.style.border = "none";
                                      iframe.id = imgElement.id;

                                      // Replace the image with the iframe
                                      imgElement.parentNode.replaceChild(
                                        iframe,
                                        imgElement
                                      );

                                      imgElement.src = e.target.result; // Set the image URL directly on the img element
                                    }
                                  }
                                };
                                reader.readAsDataURL(file); // Read the file as a data URL
                                setInputs((prevData) => ({
                                  ...prevData,
                                  ProfileFile: file,
                                })); // Update profile in state
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  col-md-6">
                        <label
                          className="form-label text-dark"
                          htmlFor="ExtraCertificationsFile"
                        >
                          Accreditation Certificate
                        </label>

                        <div className="openimage-container">
                          {inputs.AccreditationCertificateFile &&
                          typeof inputs.AccreditationCertificateFile ===
                            "string" &&
                          inputs.AccreditationCertificateFile.indexOf(
                            ".pdf"
                          ) !== -1 ? (
                            <embed
                              src={
                                inputs &&
                                inputs.AccreditationCertificateFile !== ""
                                  ? weburl + inputs.AccreditationCertificateFile
                                  : "/img/imageupload.png"
                              }
                              id="AccreditationCertificateImage"
                              alt="Driving Licence(DL)"
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                background: "#f8f9fa",
                                display: "none",
                                textAlign: "center",
                                lineHeight: "40vh",
                                borderRadius: 5,
                                objectFit: "contain",
                                border: "1px solid grey",
                              }}
                            />
                          ) : (
                            <img
                              src={
                                inputs &&
                                inputs.AccreditationCertificateFile !== ""
                                  ? weburl + inputs.AccreditationCertificateFile
                                  : "/img/imageupload.png"
                              }
                              id="AccreditationCertificateImage"
                              alt="Driving Licence(DL)"
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                background: "#f8f9fa",
                                display: "none",
                                textAlign: "center",
                                lineHeight: "40vh",
                                borderRadius: 5,
                                objectFit: "contain",
                                border: "1px solid grey",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            id="AccreditationCertificateFile"
                            className="form-control"
                            name="AccreditationCertificateFile"
                            accept=".jpg, .jpeg, .png, .pdf,.webp"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imgElement = document.getElementById(
                                    "AccreditationCertificateImage"
                                  );
                                  // if (imgElement) {
                                  //   imgElement.src = e.target.result; // Set the image URL directly on the img element
                                  // }

                                  if (imgElement) {
                                    if (
                                      e.target.result.indexOf(
                                        "data:application/pdf"
                                      ) !== -1
                                    ) {
                                      // Get the computed styles (all styles applied to the element, including those from external stylesheets)
                                      var computedStyle =
                                        window.getComputedStyle(imgElement);

                                      // Get all computed styles as a string (including properties like background, border, etc.)
                                      var allStyles = "";
                                      for (
                                        var i = 0;
                                        i < computedStyle.length;
                                        i++
                                      ) {
                                        var styleProperty = computedStyle[i];
                                        allStyles +=
                                          styleProperty +
                                          ": " +
                                          computedStyle.getPropertyValue(
                                            styleProperty
                                          ) +
                                          "; ";
                                      }

                                      // Create the iframe element
                                      var iframe =
                                        document.createElement("iframe");

                                      // Set the iframe's src (same as the img src)
                                      iframe.src = e.target.result;

                                      // Apply all the computed styles to the iframe
                                      iframe.style.cssText = allStyles;

                                      // Optional: Ensure the iframe doesn't have a border (you can skip this if you want the iframe border)
                                      iframe.style.border = "none";
                                      iframe.id = imgElement.id;

                                      // Replace the image with the iframe
                                      imgElement.parentNode.replaceChild(
                                        iframe,
                                        imgElement
                                      );

                                      // imgElement.src = e.target.result;
                                    } else {
                                      // Get the computed styles (all styles applied to the element, including those from external stylesheets)
                                      var computedStyle =
                                        window.getComputedStyle(imgElement);

                                      // Get all computed styles as a string (including properties like background, border, etc.)
                                      var allStyles = "";
                                      for (
                                        var i = 0;
                                        i < computedStyle.length;
                                        i++
                                      ) {
                                        var styleProperty = computedStyle[i];
                                        allStyles +=
                                          styleProperty +
                                          ": " +
                                          computedStyle.getPropertyValue(
                                            styleProperty
                                          ) +
                                          "; ";
                                      }

                                      // Create the iframe element
                                      var iframe =
                                        document.createElement("img");

                                      // Set the iframe's src (same as the img src)
                                      iframe.src = e.target.result;

                                      // Apply all the computed styles to the iframe
                                      iframe.style.cssText = allStyles;

                                      // Optional: Ensure the iframe doesn't have a border (you can skip this if you want the iframe border)
                                      iframe.style.border = "none";
                                      iframe.id = imgElement.id;

                                      // Replace the image with the iframe
                                      imgElement.parentNode.replaceChild(
                                        iframe,
                                        imgElement
                                      );

                                      imgElement.src = e.target.result; // Set the image URL directly on the img element
                                    }
                                  }
                                };
                                reader.readAsDataURL(file); // Read the file as a data URL
                                setInputs((prevData) => ({
                                  ...prevData,
                                  AccreditationCertificateFile: file,
                                })); // Update profile in state
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <div className="form-group">
                          <label className="mb-2 mt-2 d-block" htmlFor="state">
                            Operational Details * Departments Available:
                            <span className="text-danger"> ( Required )</span>
                          </label>
                          {data.map((item) => (
                            <div className="form-check d-block">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="departmentsAvailable"
                                id={item.name}
                                value={item.name}
                                defaultChecked={
                                  inputs.departmentsAvailable &&
                                  inputs.departmentsAvailable.includes(
                                    item.name
                                  )
                                } // Check if item is in the specialization array
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.name}
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="mb-4 col-6  ">
                        <div className="form-group">
                          <label
                            className="mb-2 mt-2 d-block"
                            htmlFor="interestedServices"
                          >
                            Interested Services
                            <span className="text-danger"> ( Required )</span>
                          </label>
                          {dataNew.map((item) => (
                            <div className="form-check d-block">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="interestedServices"
                                id={item.name}
                                value={item.name}
                                defaultChecked={
                                  inputs.interestedServices &&
                                  inputs.interestedServices.includes(item.name)
                                } // Check if item is in the specialization array
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.name}
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 
                  <div className="mb-4 col-6  ">
                    <label
                      className="form-label text-dark"
                      htmlFor="medicalRegistrationNo"
                    >
                      Medical Registration No
                      <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <input
                        type="text"
                        id="medicalRegistrationNo"
                        className="form-control custom-date"
                        name="medicalRegistrationNo"
                        value={inputs.medicalRegistrationNo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="mb-4 col-6  ">
                    <label
                      className="form-label text-dark"
                      htmlFor="medicalRegistrationNo"
                    >
                      Issuing Medical Council
                      <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <input
                        type="text"
                        id="issuingMedicalCouncil"
                        className="form-control custom-date"
                        name="issuingMedicalCouncil"
                        value={inputs.issuingMedicalCouncil}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="mb-4 col-6  ">
                    <label
                      className="form-label text-dark"
                      htmlFor="medicalRegistrationNo"
                    >
                      institution University
                      <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <input
                        type="text"
                        id="institutionUniversity"
                        className="form-control custom-date"
                        name="institutionUniversity"
                        value={inputs.institutionUniversity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="mb-4 col-6  ">
                    <label
                      className="form-label text-dark"
                      htmlFor="yearOfGraduation"
                    >
                      Salary Expectation
                      <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <input
                        type="text"
                        id="salaryExpectation"
                        className="form-control custom-date"
                        name="salaryExpectation"
                        value={inputs.salaryExpectation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="mb-4 col-6  ">
                    <label
                      className="form-label text-dark"
                      htmlFor="yearOfGraduation"
                    >
                      Professional Achievements
                      <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <input
                        type="text"
                        id="ProfessionalAchievements"
                        className="form-control custom-date"
                        name="ProfessionalAchievements"
                        value={inputs.ProfessionalAchievements}
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}

                  <div className="col-md-12 text-end">
                    {SubmitLoading ? (
                      <button
                        className="btn btn-gray-800 d-inline-flex align-items-center justify-content-center mb-4 ms-auto"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Update Profile
                      </button>
                    ) : (
                      <button
                        disabled
                        className="btn btn-gray-800 d-inline-flex align-items-center justify-content-center mb-4"
                        type="button"
                      >
                        <span className="ms-1">Loading...</span>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProfileHospitalPending;
