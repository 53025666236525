import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import Swal from "sweetalert2"; // Import SweetAlert2
import withReactContent from "sweetalert2-react-content"; // Import React components for SweetAlert2

import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingTable from "../../components/LoadingTable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import getDecryptData from "../../../helpers/getDecryptData";

const MySwal = withReactContent(Swal); // Create a SweetAlert2 instance with React components

function AllJob() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    const decryptdatajson = await getDecryptData();
    const id = decryptdatajson?._id;
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/admin/all-job-fillter?page=${currentPage}&limit=${limit}&search=${searchTerm}&userId=${id}`
      );
      setData(response.data.Job);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle delete
  const handleDelete = (categoryId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      customClass: {
        popup: "custom-swal-popup", // Add a class to the entire dialog
        confirmButton: "btn-danger", // Add a class to the confirm button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/admin/delete-job/${categoryId}`)
          .then(() => {
            // Refresh the data or update the state after successful deletion
            fetchData();
            toast.success("Deleting Jobs Success!");
          })
          .catch((error) => {
            console.error("Error Jobs data:", error);
            toast.error("Error Jobs data");
          });
      }
    });
  };

  const inputRef = React.createRef();

  const handleSearchInputChange = (e) => {
    setCurrentPage(1);
    setTotalPages(1);
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    fetchData(currentPage);
  };

  const handleStatusChange = (StatusId) => async (event) => {
    const newStatus = event.target.value;
    setLoading(true);

    try {
      await axiosInstance.put(`/admin/update-job/${StatusId}`, {
        status: newStatus,
      });
      setLoading(false);
      fetchData(currentPage);
      toast.success("Status Updated success!");
    } catch (error) {
      console.error("Error updating rating status:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <Navbar />
        <div className="py-4">
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4"> All Jobs</h1>
            </div>
            <div>
              <Link
                to="/add-Job"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="arcs"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Add Jobs
              </Link>
            </div>
          </div>
        </div>

        <div className="card  bg-light">
          <div className="table-responsive py-4">
            {loading ? (
              <LoadingTable />
            ) : (
              <>
                <div className="datatable-top">
                  <div className="datatable-dropdown">
                    <label>
                      <select
                        className="datatable-selector"
                        value={limit}
                        onChange={(e) => {
                          setlimit(parseInt(e.target.value, 10));
                          setCurrentPage(1); // Reset current page to 1 when the limit changes
                        }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                      </select>{" "}
                      entries per page
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div className="datatable-search">
                      <input
                        className="datatable-input"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                    <button className="btn btn-primary" onClick={handleSearch}>
                      {" "}
                      Search
                    </button>
                  </div>
                </div>

                <div
                  className="w-100 p-4"
                  style={{ backgroundColor: "#f2f4f6" }}
                >
                  {data.length === 0 ? (
                    <>
                      <div className="col mb-4">
                        <div
                          className={` p-4 bg-white  cards p-2  text-center fw-bold  shadow gap-4 rounded 1`}
                        >
                          <img
                            src="/search-image.svg"
                            style={{ maxWidth: "300px", width: "80%" }}
                            className="mx-auto d-block"
                          />
                          <h3 className="mt-4 fw-light">
                            No Jobs Found{" "}
                            <i className="ri-emotion-sad-line"></i>{" "}
                          </h3>
                          <p>Please ensure that you have added a job post.</p>

                          <Link
                            to="/add-Job"
                            className="btn btn-primary d-inline-flex align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="square"
                              stroke-linejoin="arcs"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            Add Jobs
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    data.map((row) => (
                      <div key={row._id} className="col mb-4">
                        <div
                          className={` ${
                            row.status === 1 ? "bg-success" : "bg-warning"
                          } cards p-2 text-white fw-bold rounded-0 shadow gap-4 rounded-top 1`}
                        >
                          <i class="ri-error-warning-line ms-2 me-2"></i>
                          {row.status === 1
                            ? "Status Active"
                            : "Status Inactive"}
                        </div>
                        <div className="bg-white cards   p-4 rounded-0 rounded-bottom shadow gap-4  mb-2  ">
                          <div className="mb-0 font-small  ">
                            <p className="fw-bold text-gray-500">
                              <b className="text-gray-900">{row.jobTitle}</b>
                              <span
                                className={`badge  ${
                                  row.status === 1 ? "bg-success" : "bg-warning"
                                }  fw-light ms-2 badge-md`}
                              >
                                {row.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </p>
                          </div>
                          <div className="  mb-3">
                            <span className="text-dark  ">{row?.c_name}</span>
                            <span className="px-2"> - </span>
                            <span className="text-dark ">{row?.address}</span>
                            <span className="px-2"> - </span>
                            <span className="text-dark ">+91-{row?.phone}</span>
                            {/* <div className="text-gray-500  ">
                            <span class="badge bg-primary fw-light ms-2">
                              {" "}
                            </span>
                          </div> */}
                          </div>

                          <div className="  mb-3">
                            {row.miniExperience === row?.maxExperience ? (
                              <>
                                <span className="text-gray-500 ">
                                  {row?.maxExperience} Years
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="text-gray-500 ">
                                  {row?.miniExperience} Years
                                </span>
                                <span className=" text-gray-500"> - </span>
                                <span className="text-gray-500 ">
                                  {row?.maxExperience} Years
                                </span>
                              </>
                            )}

                            <span className="px-1"> | </span>
                            <span className="text-gray-500 ">
                              {row?.minQualification}
                            </span>
                            <span className="px-1"> | </span>
                            <span className="text-gray-500 ">
                              ₹{row?.startSalary}
                            </span>
                            <span className=" text-gray-500"> - </span>
                            <span className="text-gray-500 ">
                              ₹{row?.endSalary} per month
                            </span>
                            <span className="px-1"> | </span>
                            <span className="text-gray-500 ">
                              {row?.jobtype === 1
                                ? "Full Time"
                                : row?.jobtype === 1 && "Part Time"}
                            </span>
                          </div>

                          <hr />
                          <div className="row align-items-center">
                            <div className="col-md-3 mb-3">
                              <select
                                className="form-select mb-0 bg-secondary text-dark mb-3"
                                id="status"
                                aria-label="Gender select example"
                                style={{ color: "black" }}
                                name="status"
                                onChange={handleStatusChange(row._id)}
                              >
                                <option disabled selected>
                                  Select Status
                                </option>
                                <option value={1} selected={row.status === 1}>
                                  Active
                                </option>
                                <option value={2} selected={row.status === 0}>
                                  Inactive
                                </option>
                              </select>
                            </div>

                            <div className="col-md-9 mb-3 text-end  ">
                              <Link
                                type="button"
                                className="btn btn-primary d-inline-flex align-items-center bg-dark me-2 mb-3"
                              >
                                <i className="ri-pencil-fill me-1"></i> Edit Job
                              </Link>
                              <Link
                                to={`/job-view/${row._id}`}
                                class="btn btn-outline-primary mb-3"
                              >
                                <i className="ri-file-reduce-fill"></i> View All
                                Resume
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <div className="datatable-bottom">
                  <div className="datatable-info"> </div>
                  <nav className="datatable-pagination">
                    <ul class="datatable-pagination-list">
                      <li
                        className={`datatable-pagination-list-item ${
                          currentPage === 1
                            ? "datatable-hidden"
                            : "datatable-active"
                        }`}
                      >
                        <button
                          data-page="1"
                          class="datatable-pagination-list-item-link"
                          onClick={handlePreviousPage}
                          disabled={loading || currentPage === 1}
                          aria-label="Page 1"
                        >
                          ‹
                        </button>
                      </li>
                      <li>
                        <div className="datatable-info py-1 px-3">
                          Showing Page{" "}
                          <strong>
                            Showing Page {currentPage} of {totalPages}
                          </strong>{" "}
                        </div>
                      </li>
                      <li
                        className={`datatable-pagination-list-item ${
                          currentPage === totalPages
                            ? "datatable-hidden"
                            : "datatable-active"
                        }`}
                      >
                        <button
                          data-page="2"
                          class="datatable-pagination-list-item-link"
                          aria-label="Page 2"
                          onClick={handleNextPage}
                          disabled={loading || currentPage === totalPages}
                        >
                          {" "}
                          {loading ? "Loading..." : "›"}{" "}
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export default AllJob;
