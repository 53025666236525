import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import getDecryptData from "../../../helpers/getDecryptData";

const AddJob = () => {
  const [loading, setLoading] = useState(true); // Add loading state

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state
  const [filteredCities, setFilteredCities] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    skill: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let newValue;

    if (type === "checkbox") {
      if (name === "skill") {
        newValue = checked
          ? [...(formData.skill || []), value]
          : (formData.skill || []).filter((item) => item !== value);
      } else {
        newValue = checked;
      }
    } else if (type === "radio") {
      newValue = value; // Directly assign the selected radio button's value
    } else {
      newValue = value; // For other inputs
    }

    // Capitalize text for the 'username' field
    if (name === "state") {
      const selectedState = data2.find((state) => state._id === newValue);
      if (selectedState) {
        setFilteredCities(selectedState.cities);
        setFormData((prevData) => ({
          ...prevData,
          statename: selectedState.name,
          city: "",
        }));
      }
      console.log("selectedState", selectedState, newValue, data);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const [showCode, setShowCode] = useState(false);
  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const toggleShowCode = () => {
    setShowCode(!showCode);
  };

  //form handle
  const fetchUserById = async () => {
    const decryptdatajson = await getDecryptData();
    const id = decryptdatajson?._id;

    const credentials = {
      id: id,
    };

    try {
      const { data } = await axiosInstance.post("/auth-user", credentials);
      const { success, token, existingUser, message } = data;

      if (success) {
        setFormData((prevData) => ({
          ...prevData,
          c_name: existingUser.username || "",
          phone: existingUser.phone || "",
          email: existingUser.email || "",
          address: existingUser.address || "",
          jobTimings: "9:30 AM - 6:30 PM | Monday to Saturday",
          interviewDetails: " 11:00 AM - 4:00 PM | Monday to Saturday",
        }));
      }

      console.log("success", existingUser);
    } catch (error) {
      console.error("Error during login:", error);
      // Handle network errors, API issues, etc.
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchUserById();
  }, []);

  const submitData = async () => {
    setSubmitLoading(false);
    const decryptdatajson = await getDecryptData();
    const id = decryptdatajson?._id;
    const mydata = {
      ...formData,
      userId: id,
    };
    console.log(formData);
    try {
      await axiosInstance.post("/admin/add-job", mydata);
      console.log("Add job successfully!");
      toast.success("Add job successfully!");
      navigate("/all-job");
    } catch (error) {
      console.error("Error On job add:", error);
      console.log(formData);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    console.log("response.data start");

    try {
      const response = await axiosInstance.get("/get-all-skill");
      console.log("response.data.Skill,", response.data.Skill);
      setData(response.data.Skill);

      const response2 = await axiosInstance.get("/get-all-zones");
      console.log("response.data.Zones,", response2.data.Zones);
      setData2(response2.data.Zones);

      const response3 = await axiosInstance.get("/get-all-specialization");
      console.log("response.data.Zones,", response3.data.Specialization);
      setData3(response3.data.Specialization);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <a href="#">
                  <svg
                    className="icon icon-xxs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Job
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4">Add Job</h1>
            </div>
            <div>
              {" "}
              <Link
                to="/all-job"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Go back
              </Link>
            </div>
          </div>
        </div>
        <form method="post" id="add-blog-form">
          <div className="row">
            <div className="col-md-8 mb-4">
              <div className="card border-0 shadow components-section mb-4">
                <div className="card-body ">
                  <div className="row mb-4">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Job Type <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                              <label
                                id="jobtype1"
                                className={`btn 
                                ${
                                  formData.jobtype === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="jobtype"
                                  id="jobtype1"
                                  value="1"
                                  onChange={handleChange}
                                />
                                Full Time
                              </label>

                              <label
                                id="jobtype2"
                                className={`btn 
                                ${
                                  formData.jobtype === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="jobtype"
                                  id="jobtype2"
                                  value="2"
                                  onChange={handleChange}
                                />{" "}
                                Part Time
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Job Title <span className="text-danger">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="jobTitle"
                              value={formData.jobTitle}
                              name="jobTitle"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobRole">
                              Job Role / Area of work{" "}
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select
                              className="form-control"
                              id="jobRole"
                              name="jobRole"
                              value={formData.jobRole}
                              onChange={handleChange}
                            >
                              <option selected disabled>
                                {" "}
                                {loading ? "Loading..." : "Select Job Role"}
                              </option>
                              {data3.map((item) => (
                                <option key={item._id} value={item.name}>
                                  {item.name}
                                </option>
                              ))}{" "}
                              ;
                            </select>
                          </div>
                        </div>

                        <div className="mb-4 col-6  ">
                          <label
                            className="form-label text-dark"
                            htmlFor="email"
                          >
                            Job Location State{" "}
                            <span className="text-danger"> ( Required )</span>
                          </label>
                          <div className="input-group    input-sm">
                            <select
                              className="form-control"
                              id="state"
                              name="state"
                              value={formData.state}
                              onChange={handleChange}
                            >
                              <option selected disabled>
                                {" "}
                                {loading ? "Loading..." : "Select State"}
                              </option>
                              {data2.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}{" "}
                              ;
                            </select>
                          </div>
                        </div>

                        <div className="mb-4 col-6 ">
                          <label
                            className="form-label text-dark"
                            htmlFor="city"
                          >
                            Job Location City{" "}
                            <span className="text-danger"> ( Required )</span>
                          </label>
                          <div className="input-group    input-sm">
                            <select
                              className="form-control"
                              id="city"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                            >
                              <option selected value={""}>
                                {" "}
                                {loading ? "Loading..." : "Select City"}
                              </option>
                              {filteredCities.map((city, index) => (
                                <option key={index} value={city}>
                                  {city}
                                </option>
                              ))}{" "}
                              ;
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobOpen"> No Of Openings</label>
                            <input
                              type="number"
                              className="form-control"
                              id="jobOpen"
                              value={formData.jobOpen}
                              name="jobOpen"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-0 shadow components-section mb-4">
                <div className="card-body ">
                  <h6> Candidate Requirement</h6>

                  <div className="row mb-4">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Total Experience Of Candidate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                              <label
                                id="totalExprience1"
                                className={`btn 
                                ${
                                  formData.totalExprience === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="totalExprience"
                                  id="totalExprience1"
                                  value="1"
                                  onChange={handleChange}
                                />
                                Any
                              </label>

                              <label
                                id="totalExprience2"
                                className={`btn 
                                ${
                                  formData.totalExprience === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="totalExprience"
                                  id="totalExprience2"
                                  value="2"
                                  onChange={handleChange}
                                />{" "}
                                Fresher Only
                              </label>

                              <label
                                id="totalExprience3"
                                className={`btn 
                                ${
                                  formData.totalExprience === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="totalExprience"
                                  id="totalExprience3"
                                  value="3"
                                  onChange={handleChange}
                                />{" "}
                                Experience Only
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Minimum Experience
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select
                              className="form-control"
                              name="miniExperience"
                            >
                              <option disabled=""> Select Experience</option>
                              <option value={1}>1 years</option>
                              <option value={2}>2 Years</option>
                              <option value={3}>3 Years</option>
                              <option value={4}>4 Years</option>
                              <option value={5}>5 Years</option>
                              <option value={6}>6 Years</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="maxExperience">
                              Maxmium Experience
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select
                              className="form-control"
                              name="maxExperience"
                            >
                              <option disabled=""> Select Experience</option>
                              <option value={1}>1 years</option>
                              <option value={2}>2 Years</option>
                              <option value={3}>3 Years</option>
                              <option value={4}>4 Years</option>
                              <option value={5}>5 Years</option>
                              <option value={6}>6 Years</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Minimum Qualification
                              <span className="text-danger">*</span>
                            </label>

                            <div className="col-12">
                              {[
                                { label: "<10th Pass", value: "<10 th Pass" },
                                {
                                  label: "10th pass or above",
                                  value: "10th pass or above",
                                },
                                {
                                  label: "12th pass or above",
                                  value: "12th pass or above",
                                },
                                {
                                  label: "Graduate/ Post Graduate",
                                  value: "Graduate/ Post Graduate",
                                },

                                { label: "MBBS", value: "MBBS" },
                                {
                                  label: "Equivalent degree",
                                  value: "Equivalent degree",
                                },
                                {
                                  label: "Diploma in Pharmacy",
                                  value: "Diploma in Pharmacy",
                                },
                                { label: "B.Pharm", value: "B.Pharm" },
                                {
                                  label: "Diploma in Nursing",
                                  value: "Diploma in Nursing",
                                },
                                {
                                  label: "B.Sc Nursing",
                                  value: "B.Sc Nursing",
                                },
                                {
                                  label: "Diploma in Medical Lab Technology",
                                  value: "Diploma in Medical Lab Technology",
                                },
                                { label: "BMLT", value: "BMLT" },
                                {
                                  label: "Bachelor of Physiotherapy (BPT)",
                                  value: "Bachelor of Physiotherapy (BPT)",
                                },
                                {
                                  label: "B.Sc in Radiology",
                                  value: "B.Sc in Radiology",
                                },
                                {
                                  label: "B.Sc in Imaging Technology",
                                  value: "B.Sc in Imaging Technology",
                                },
                                {
                                  label: "Bachelor of Dental Surgery (BDS)",
                                  value: "Bachelor of Dental Surgery (BDS)",
                                },
                                {
                                  label: "Certificate in Paramedical Sciences",
                                  value: "Certificate in Paramedical Sciences",
                                },
                                {
                                  label: "Diploma in Paramedical Sciences",
                                  value: "Diploma in Paramedical Sciences",
                                },
                                {
                                  label: "Bachelor’s in Public Health (BPH)",
                                  value: "Bachelor’s in Public Health (BPH)",
                                },
                                {
                                  label: "Bachelor’s in Biotechnology",
                                  value: "Bachelor’s in Biotechnology",
                                },
                                {
                                  label: "Bachelor’s in Microbiology",
                                  value: "Bachelor’s in Microbiology",
                                },
                                {
                                  label: "Certificate in Medical Assisting",
                                  value: "Certificate in Medical Assisting",
                                },
                                {
                                  label: "Diploma in Medical Assisting",
                                  value: "Diploma in Medical Assisting",
                                },
                              ].map(({ label, value }, index) => (
                                <label
                                  key={index}
                                  className={`btn ms-${
                                    index === 0 ? "0" : "2"
                                  } ${
                                    formData.minQualification === value
                                      ? "btn-primary"
                                      : "btn-pill btn-outline-primary"
                                  }`}
                                  type="button"
                                >
                                  <input
                                    className="form-check-input me-1"
                                    type="radio"
                                    name="minQualification"
                                    value={value}
                                    onChange={handleChange}
                                  />{" "}
                                  {label}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Gender Preference
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.gender === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="gender"
                                  value="1"
                                  onChange={handleChange}
                                />{" "}
                                Both gender allowed
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.gender === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="gender"
                                  value="2"
                                  onChange={handleChange}
                                />{" "}
                                Male
                              </label>
                              <label
                                className={`btn ms-2 
                                ${
                                  formData.gender === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="gender"
                                  value="3"
                                  onChange={handleChange}
                                />{" "}
                                Female
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              English Required
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.englishReq === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="englishReq"
                                  value="1"
                                  onChange={handleChange}
                                />{" "}
                                Does not speak english
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.englishReq === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="englishReq"
                                  value="2"
                                  onChange={handleChange}
                                />{" "}
                                Speaks Thoda English
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.englishReq === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="englishReq"
                                  value="3"
                                  onChange={handleChange}
                                />{" "}
                                Speaks Good English
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.englishReq === "4"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input me-1"
                                  type="radio"
                                  name="englishReq"
                                  value="4"
                                  onChange={handleChange}
                                />{" "}
                                Speaks Fluent English
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              I want call from candidate within
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.callCandidate ===
                                  "Nearby areas (up to 10 km)"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Nearby areas (up to 10 km)
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="callCandidate"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.callCandidate === "Anywhere in Delhi"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Anywhere in Delhi
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="callCandidate"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="reLocate">
                              Would you like to receive candidates applications
                              from nearby cities of delhi if they are willing to
                              relocate?
                              <span className="text-danger ms-2">*</span>
                            </label>

                            <div className="col-12">
                              <label
                                htmlFor="reLocate1"
                                className={`btn 
      ${
        formData.reLocate === "1"
          ? "btn-primary"
          : "btn-pill btn-outline-primary"
      }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input  me-1"
                                  type="radio"
                                  name="reLocate"
                                  value="1"
                                  id="reLocate1"
                                  onChange={handleChange}
                                  checked={formData.reLocate === "1"}
                                />{" "}
                                Yes
                              </label>

                              <label
                                htmlFor="reLocate2"
                                className={`btn ms-2 ${
                                  formData.reLocate === "0"
                                    ? "btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                <input
                                  className="form-check-input  me-1"
                                  type="radio"
                                  name="reLocate"
                                  value="0"
                                  id="reLocate2"
                                  onChange={handleChange}
                                  checked={formData.reLocate === "0"}
                                />{" "}
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-2">
                            <label htmlFor="reLocate">
                              Monthly In-hand Salary
                              <span className="text-danger ms-2">*</span>
                            </label>
                          </div>
                          <div className="mb-4 col-md-4 ">
                            <div class="d-flex">
                              <input
                                type="number"
                                className="form-control col-5 rounded-0 rounded-start  "
                                placeholder="Start From"
                                aria-label="Start"
                                aria-describedby="basic-addon1"
                                style={{ width: "auto" }}
                                name="startSalary"
                                onChange={handleChange}
                                value={formData.startSalary}
                              />

                              <span
                                class="input-group-text col-2 rounded-0 bg-dark text-white"
                                style={{ background: "black" }}
                              >
                                To
                              </span>

                              <input
                                type="number"
                                className="form-control col-5 rounded-0 rounded-end"
                                placeholder="End to"
                                aria-label="End"
                                aria-describedby="basic-addon1"
                                style={{ width: "auto" }}
                                name="endSalary"
                                onChange={handleChange}
                                value={formData.endSalary}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Job info / Job description
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <textarea
                              maxLength={500}
                              className="form-control"
                              name="jobDescription"
                              onChange={handleChange}
                              value={formData.jobDescription}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-4">
                          <label htmlFor="skill" className=" d-block mb-2">
                            Skill
                            <span className="text-danger">*</span>{" "}
                          </label>

                          {data.map((item) => (
                            <div className="d-inline">
                              <label
                                type="button"
                                className={`btn ms-2 
                                btn-pill ${
                                  formData.skill &&
                                  formData.skill.includes(item.name)
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                                } cursor`}
                                htmlFor={item.name}
                              >
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  name="skill"
                                  id={item.name}
                                  value={item.name}
                                  onChange={handleChange}
                                  defaultChecked={
                                    formData.skill &&
                                    formData.skill.includes(item.name)
                                  } // Check if item is in the specialization array
                                />

                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Job Timings
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <textarea
                              maxLength={500}
                              className="form-control"
                              name="jobTimings"
                              onChange={handleChange}
                              value={formData.jobTimings}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Interview Details
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <textarea
                              maxLength={500}
                              className="form-control"
                              name="interviewDetails"
                              onChange={handleChange}
                              value={formData.interviewDetails}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-0 shadow components-section mb-4">
                <div className="card-body ">
                  <h5 class="mb-3"> About You Company</h5>

                  <div className="row mb-4 mt-2 border-top pt-3">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="c_name">
                          Company Name <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="c_name"
                          name="c_name"
                          onChange={handleChange}
                          value={formData.c_name}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="p_name">
                          Contact Person Name{" "}
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="p_name"
                          name="p_name"
                          onChange={handleChange}
                          value={formData.p_name}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="phone">
                          Phone Number
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend rounded-0  ">
                            <span
                              className="input-group-text rounded-0 rounded-start"
                              style={{
                                color: "white",
                                backgroundColor: "black",
                              }}
                              id="basic-addon1"
                            >
                              +91
                            </span>
                          </div>
                          <input
                            type="tel"
                            className="form-control "
                            placeholder="Phone Number"
                            name="phone"
                            onChange={handleChange}
                            maxLength={10}
                            value={formData.phone}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="p_name">
                          Email Id
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={formData.email}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="personProfile">
                          Contact Person Profile
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <select
                          className="form-control"
                          name="personProfile"
                          id="personProfile"
                          onChange={handleChange}
                        >
                          <option selected disabled>
                            {" "}
                            Select Contact Person
                          </option>
                          <option value={"HR/Recruiter"}>HR/Recruiter</option>
                          <option value={"Owner"}>Owner</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="maxExperience">
                          Size of Organization
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <select
                          className="form-control"
                          name="sizeOrganization"
                          onChange={handleChange}
                        >
                          <option selected disabled>
                            {" "}
                            Select Size
                          </option>
                          <option value={"11-50"}>11-50 </option>
                          <option value={"50-100"}>50-100 </option>
                          <option value={"100-200"}>100-200 </option>
                          <option value={"200-400"}>200-400 </option>
                          <option value={"400-800"}>400-800 </option>
                          <option value={"800-1600+"}>800-1600+ </option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <label htmlFor="address">
                          Address
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          onChange={handleChange}
                          value={formData.address}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">
                  <div className="row">
                    {/* 

                    <div className="col-4 ">
                      <div className="mb-4">
                        <label className="my-1 me-2" htmlFor="status">
                          Status
                        </label>
                      </div>
                    </div>
                    <div className="col-8 ">
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="status"
                            id="status"
                            defaultChecked="checked"
                            defaultValue={1} onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-12">
                    {SubmitLoading ? (
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={submitData}
                      >
                        Add Job
                      </button>
                    ) : (
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        disabled
                      >
                        <span class="ms-1">Loading...</span>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddJob;
